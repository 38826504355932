body {
  background-color: #e6e0bb;
  font-family: 'Roboto', sans-serif;
  color: #282828;
  margin: 0 80px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  float: right;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: #d2292d;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* .privacy-policy-container {
  
} */